import ApiMan from "../ApiMan";

const route = {
  name: "getuser",
  required: ["token", "id"],
  entry: async (data) => {
    return route.preReq(data);
  },
  preReq: async (data) => {
    if (!data.token || data.token === "") {
      throw new Error("Invalid token");
    }

    if (!data.id || data.id === "") {
      data.id = "me";
    }

    let config = {
      method: "get",
      url: "/user/" + data.id,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + data.token,
      },
    };

    return route.postReq(ApiMan.request(config));
  },
  postReq: async (request) => {
    const response = await request;

    console.log(response.data);

    return response.data;
  },
};

export default route;
