export default class CookieMan {
  static cookies = {};

  static init = () => {
    this.loadLocal();
  };

  static loadLocal = () => {
    if (document.cookie === "") document.cookie = "{}";
    this.cookies = JSON.parse(document.cookie);
  };

  static saveLocal = () => {
    document.cookie = JSON.stringify(this.cookies);
  };

  static get = (key) => {
    return this.cookies[key];
  };

  static set = (key, value) => {
    this.cookies[key] = value;
    this.saveLocal();
  };
}
