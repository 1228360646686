import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import NoPage from "./pages/NoPage";
import Login from "./pages/Login";
import Error from "./pages/Error";
import ApiMan from "./utils/ApiMan/ApiMan";
import CookieMan from "./utils/CookieMan/CookieMan";
import ForgotPassword from "./pages/ForgotPassword";
import HomeTemplate from "./pages/HomeTemplate";

export default function App() {
  CookieMan.init();
  ApiMan.init();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="home" element={<HomeTemplate />}></Route>
        <Route path="login" element={<Login />}></Route>
        <Route path="/" element={<Login />}></Route>
        <Route path="dashboard" element={<Dashboard />}></Route>
        <Route path="forgot" element={<ForgotPassword />}></Route>
        <Route path="reset" element={<ForgotPassword />}></Route>
        <Route path="u/:username" element={<NoPage />}></Route>
        <Route path="*" element={<Error />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
