import * as React from "react";
import { useParams } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import AppTheme from "../theme/AppTheme";
import Paper from "@mui/material/Paper";
import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,
} from "../theme/customizations";

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};

export default function NoPage(props) {
  const { username } = useParams();

  return (
    <AppTheme {...props} themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: "flex" }}>
        <Paper>
          <h1>404:</h1>
          <h1>ID {username} not found</h1>
        </Paper>
      </Box>
    </AppTheme>
  );
}
