import * as React from "react";
import MuiAvatar from "@mui/material/Avatar";
import MuiListItemAvatar from "@mui/material/ListItemAvatar";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select, { selectClasses } from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import DevicesRoundedIcon from "@mui/icons-material/DevicesRounded";
import ReceiptIcon from "@mui/icons-material/Receipt";
import TimelineIcon from "@mui/icons-material/Timeline";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import BadgeIcon from "@mui/icons-material/Badge";

const Avatar = styled(MuiAvatar)(({ theme }) => ({
  width: 28,
  height: 28,
  backgroundColor: (theme.vars || theme).palette.background.paper,
  color: (theme.vars || theme).palette.text.secondary,
  border: `1px solid ${(theme.vars || theme).palette.divider}`,
}));

const ListItemAvatar = styled(MuiListItemAvatar)({
  minWidth: 0,
  marginRight: 12,
});

export default function SelectContent({ allowedPaths }) {
  const [company, setCompany] = React.useState("int");

  const handleChange = (event) => {
    setCompany(event.target.value);
  };

  return (
    <Select
      labelId="company-select"
      id="company-simple-select"
      value={company}
      onChange={handleChange}
      displayEmpty
      inputProps={{ "aria-label": "Select company" }}
      fullWidth
      sx={{
        maxHeight: 56,
        width: 215,
        "&.MuiList-root": {
          p: "8px",
        },
        [`& .${selectClasses.select}`]: {
          display: "flex",
          alignItems: "center",
          gap: "2px",
          pl: 1,
        },
      }}
    >
      {[...allowedPaths].includes("int") && (
        <MenuItem value="int">
          <ListItemAvatar>
            <Avatar alt="Intranet">
              <DevicesRoundedIcon sx={{ fontSize: "1rem" }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="INTRA" secondary="Intranet Empleados" />
        </MenuItem>
      )}
      {[...allowedPaths].includes("sga") && (
        <MenuItem value="sga">
          <ListItemAvatar>
            <Avatar alt="Sistema de gestion de abastecimiento">
              <Inventory2Icon sx={{ fontSize: "1rem" }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="SGA" secondary="Gestion de Abastecimiento" />
        </MenuItem>
      )}
      {[...allowedPaths].includes("sed") && (
        <MenuItem value="sed">
          <ListItemAvatar>
            <Avatar alt="Sistema de evaluacion de desenpeño">
              <TimelineIcon sx={{ fontSize: "1rem" }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="SED" secondary="Evaluación de Desenpeño" />
        </MenuItem>
      )}
      {[...allowedPaths].includes("srg") && (
        <MenuItem value="srg">
          <ListItemAvatar>
            <Avatar alt="sistema de rendicion de gastos">
              <ReceiptIcon sx={{ fontSize: "1rem" }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="SRG" secondary="Rendicion de Gastos" />
        </MenuItem>
      )}
      {[...allowedPaths].includes("iam") && (
        <MenuItem value="iam">
          <ListItemAvatar>
            <Avatar alt="sistema de gestion de usuarios y permisos">
              <BadgeIcon sx={{ fontSize: "1rem" }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="IAM" secondary="Identidad y Permisos" />
        </MenuItem>
      )}
    </Select>
  );
}
