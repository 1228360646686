import axios from "axios";
import CookieMan from "../CookieMan/CookieMan";

export default class ApiMan {
  static token = null;
  static routes = {};
  static axios = null;

  //TODO: change base url
  static baseUrl = "https://api.sauce.cl";
  //static baseUrl = "http://localhost:3010";

  static init = () => {
    if (!this.axios) {
      this.axios = axios;
    }

    this.token = CookieMan.get("token");

    if (this.token) {
      this.axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.token}`;
    }

    this.axios.defaults.headers.common["Content-Type"] = "application/json";
  };

  static request = (config) => {
    config.url = this.baseUrl + config.url;

    return axios.request(config);
  };

  // Load routes dynamically
  static loadRoutes = () => {
    ApiMan.axios = axios;
    this.routes = {}; // Initialize routes to ensure a fresh load

    // Dynamically import all .route.js files in the requests directory
    const context = require.context("./requests", false, /\.route\.js$/);

    context.keys().forEach((key) => {
      const route = context(key).default;

      const requiredProperties = ["name", "entry"];
      requiredProperties.forEach((prop) => {
        if (!route[prop]) {
          throw new Error(
            `Route file ${key} is missing required property '${prop}'.`
          );
        }
      });

      if (this.routes[route.name]) {
        throw new Error(
          `Duplicate route name detected: ${route.name} in file ${key}`
        );
      }

      if (typeof route.entry !== "function") {
        throw new Error(
          `Invalid 'entry' function '${route.entry}' in route ${route.name} defined in file ${key}.`
        );
      }

      this.routes[route.name] = route;
    });
  };

  // Execute a route by name
  static exec = async (routeName, data) => {
    if (!this.routes || Object.keys(this.routes).length === 0) {
      this.loadRoutes();
    }

    const route = this.routes[routeName];
    if (!route) {
      throw new Error(`Route ${routeName} not found`);
    }

    // Validate required parameters
    if (route.required) {
      route.required.forEach((req) => {
        if (!data[req]) {
          throw new Error(`Route ${route.name} requires ${req}`);
        }
      });
    }

    data.axios = this.axios;

    // Execute the route entry point
    return await route.entry(data);
  };

  static setAccessToken(token) {
    this.token = token;
    CookieMan.set("token", token);
  }
}
