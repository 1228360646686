import ApiMan from "../ApiMan";

const route = {
  name: "signup",
  required: ["user", "password", "name", "lastname", "rut"],
  entry: async (data) => {
    return route.preReq(data);
  },
  preReq: async (data) => {
    if (
      !data.user.match(/^[0-9]{8}-[0-9Kk]$/i) &&
      !data.user.match(/^[0-9]{9}$/i) &&
      !data.user.match(/^.+@.+\..+$/i)
    ) {
      throw new Error("Invalid user format");
    }

    if (data.password.length < 8) {
      throw new Error("Password is too short");
    }

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/auth/login",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: JSON.stringify({
        rut: data.user,
        password: data.password,
      }),
    };

    return route.postReq(ApiMan.request(config));
  },
  postReq: async (request) => {
    const response = await request;

    if (
      !response.data.access_token ||
      !response.data.renew_token ||
      ![200, 201].includes(response.status)
    ) {
      throw new Error("Invalid response");
    }

    ApiMan.setAccessToken(response.data.access_token);

    return response.data;
  },
};

export default route;
