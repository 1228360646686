import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer, { drawerClasses } from "@mui/material/Drawer";
import {
  TextField,
  Button,
  Stack,
  Link,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useColorScheme } from "@mui/material/styles";
import ApiMan from "../utils/ApiMan/ApiMan";
import { useNavigate } from "react-router-dom";

const Drawer = styled(MuiDrawer)({
  width: { md: "30%" },
  maxWidth: 550,
  flexShrink: 0,
  boxSizing: "border-box",
  mt: 10,
  [`& .${drawerClasses.paper}`]: {
    width: "100%",
    boxSizing: "border-box",
    maxWidth: 550,
  },
});

export default function SideLogin() {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { mode } = useColorScheme();
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);
    setError("");

    try {
      const response = await ApiMan.exec("login", {
        user: user,
        password: password,
      });

      if (response.accessToken) navigate("/home");
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        display: "block",
        [`& .${drawerClasses.paper}`]: {
          backgroundColor: "background.paper",
        },
      }}
    >
      <Stack
        spacing={2}
        height={"100%"}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          mt: "calc(var(--template-frame-height, 0px) + 4px)",
          p: 1,
        }}
      >
        <img
          src={
            mode === "light"
              ? "https://www.constructoraelsauce.cl/resources/media/elsauce/old/logo_transp_blue.png"
              : "https://www.constructoraelsauce.cl/resources/media/elsauce/old/logo_transp_white.png"
          }
          style={{ paddingTop: "25px" }}
          width="70%"
          alt="El Sauce Logo"
        />

        <Stack spacing={1.5} style={{ width: "90%" }}>
          <TextField
            id="user-input"
            label="Rut o correo"
            variant="outlined"
            value={user}
            onChange={(e) => setUser(e.target.value)}
          />

          <TextField
            id="pass-input"
            label="Contraseña"
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button variant="contained" onClick={handleLogin} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Entrar"}
          </Button>
          {error && (
            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
              {error}
            </Typography>
          )}
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Link href="forgot">¿Olvidaste tu contraseña?</Link>
          </Stack>
          <Button variant="outlined">
            <img
              src="https://img.icons8.com/color/16/000000/google-logo.png"
              alt="Google Logo"
              style={{
                marginRight: "10px",
                marginBottom: "2px",
              }}
            />{" "}
            Entrar con Google
          </Button>
        </Stack>

        <p> © 2024. Constructora El Sauce S.A.</p>
      </Stack>
    </Drawer>
  );
}
